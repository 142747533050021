export const cyclePathData = [
  'M248.114,1.0005 C111.084,1.0005 3.12638804e-13,112.0855 3.12638804e-13,249.1145 C3.12638804e-13,386.1445 111.084,497.2295 248.114,497.2295',
  'M247.7214,1.0005 C316.2364,1.0005 378.2644,28.7715 423.1634,73.6715 L510.8854,161.3935',
  'M950.278,497.229 C881.763,497.229 819.735,469.458 774.836,424.558 L687.114,336.836',
  'M949.8854,497.229 C1086.9154,497.229 1197.9994,386.144 1197.9994,249.115 C1197.9994,112.085 1086.9154,1 949.8854,1',
  'M248.1144,497.229 C316.6334,497.229 378.6644,469.455 423.5644,424.55 L511.2784,336.836 L599.0004,249.115 L686.7214,161.393 L774.4354,73.679 C819.3364,28.775 881.3674,1 949.8854,1'
];

export const cyclePathData2 = [
  'M0,249.1142 C0,386.1432 111.084,497.2282 248.114,497.2282',
  'M248.1143,1.001 C111.0843,1.001 0.0003,112.085 0.0003,249.114',
  'M247.7217,1 C316.2367,1 378.2647,28.771 423.1637,73.672 L510.8857,161.393',
  'M950.2783,497.2285 C881.7633,497.2285 819.7353,469.4575 774.8363,424.5575 L687.1143,336.8355',
  'M949.8857,497.2285 C1086.9157,497.2285 1197.9997,386.1435 1197.9997,249.1145',
  'M1198,249.1142 C1198,112.0852 1086.916,1.0002 949.886,1.0002',
  'M248.1147,497.2285 C316.6337,497.2285 378.6647,469.4545 423.5647,424.5495 L511.2787,336.8355 L599.0007,249.1145 L686.7217,161.3925 L774.4357,73.6785 C819.3367,28.7745 881.3677,1.0005 949.8857,1.0005',
];